import React from "react"
import styled, { css } from 'styled-components'

const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
`;

export default LabelContainer;

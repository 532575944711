import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Button from "../components/button"
import Label from "../components/label"
import LabelContainer from "../components/label-container"
import { FaHistory, FaProjectDiagram, FaArrowLeft } from 'react-icons/fa';

export default () => (
  <div style={{ color: `teal` }}>
    <Navbar>
      <Link to="/"><FaArrowLeft /></Link>
    </Navbar>
    <Header headerText="Abilities" />
    <p>Along with being able to eat my own weight in food, super-speed (useful for burning calories from previous ability) and an uncanny knack for knowing the latest possible time I can depart for long car journies whilst still arriving on time, I have also developed the superpower of coding, to a resaonable degree.</p>

    <p>Here's a list of labels to colourfully display stuff I have experience with and show that I can use flexbox, though you'll probably find it more useful to check my work history and projects pages in the links below.</p>
    <LabelContainer>
      <Label>Ruby</Label>
      <Label>Ruby on Rails</Label>
      <Label>Javascript</Label>
      <Label>ReactJS</Label>
      <Label>Semantic HTML</Label>
      <Label>SASS</Label>
      <Label>MongoDB</Label>
      <Label>MySQL</Label>
      <Label>Postgresql</Label>
      <Label>TDD & BDD</Label>
      <Label>Agile</Label>
      <Label>Cucumber</Label>
      <Label>RSpec</Label>
      <Label>CircleCI</Label>
      <Label>Heroku</Label>
    </LabelContainer>

    <Link to="/history"><Button>Work <FaHistory /></Button></Link>
    <Link to="/projects"><Button>Projects <FaProjectDiagram /></Button></Link>
    <Footer />
  </div>
)
import React from "react"
import styled, { css } from 'styled-components'

const Label = styled.div`
    background-color: #2f2f2f;
    font-size: 0.875em;
    padding: 8px 20px;
    margin-right: 5px;
    margin-top: 15px;
    border-radius: 10px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.25s ease;
    &:hover {
        background-color: #3A44F0;
    }
`;

export default Label;
